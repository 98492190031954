<template>
  <el-main>
    <el-form label-width="200px" class="addForm">
      <el-form-item label="名称：">
        <el-input v-model="addForm.teacher"></el-input>
      </el-form-item>
      <el-form-item label="工作岗位：">
        <el-input v-model="addForm.teacher_comefrom"></el-input>
      </el-form-item>
      <el-form-item label="上岗时间：">
        <el-date-picker
          v-model="teacher_income_time"
          @change="getTime"
          type="datetime"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="硬件设备：">
        <el-select
          v-model="addForm.shipper_id"
          filterable
          placeholder="请选择设备"
          style="width: 100%"
        >
          <el-option
            v-for="item in shipperList"
            :key="item.id"
            :label="item.equipment_no"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="性别：">
        <el-radio-group v-model="addForm.sex">
          <el-radio :label="0">男</el-radio>
          <el-radio :label="1">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="年龄：">
        <el-input v-model="addForm.age"></el-input>
      </el-form-item>
      <el-form-item label="现住址：">
        <el-input v-model="addForm.address"></el-input>
      </el-form-item>
      <el-form-item label="电话：">
        <el-input v-model="addForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="省市区：" class="city">
        <div class="box">
          <el-select
            v-model="addForm.province_id"
            placeholder="请选择"
            @change="(val) => sonArea(0, val)"
          >
            <el-option
              v-for="(item, index) in area"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="addForm.city_id"
            placeholder="请选择"
            @change="(val) => sonArea(1, val)"
          >
            <el-option
              v-for="(item, index) in cityArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="addForm.area_id"
            placeholder="请选择"
            @change="(val) => sonArea(2, val)"
          >
            <el-option
              v-for="(item, index) in areaArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="岗位：">
        <el-input v-model="addForm.positioning"></el-input>
      </el-form-item>
      <el-form-item label="当前温度：">
        <el-input v-model="addForm.current_temperature"></el-input>
      </el-form-item>
      <el-form-item label="最高温度：">
        <el-input v-model="addForm.maximum_temperature"></el-input>
      </el-form-item>
      <el-form-item label="备注：">
        <el-input v-model="addForm.remark"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="saveForm">保存</el-button>
      </el-form-item>
    </el-form>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      area: [],
      cityArr: [],
      areaArr: [],
      showAdd: !1,
      teacherList: [],
      shipperList: [],
      teacher_income_time: "",
      addForm: {
        teacher: "",
        teacher_comefrom: "",
        teacher_income_time: "",
        sex: 0,
        age: "",
        address: "",
        mobile: "",
        province_id: "",
        shipper_id: "",
        city_id: "",
        area_id: "",
        positioning: "",
        current_temperature: "",
        maximum_temperature: "",
        remark: "",
      },
    };
  },
  created() {
    let id = this.$route.query.id;
    if (id) {
      this.getShipperList();
      this.handleInfo(id);
    } else {
      this.getArea();
      this.getShipperList();
    }
  },
  methods: {
    async handleInfo(id) {
      await this.getArea();
      this.$axios.post(this.$api.teacher.info, { id: id }).then((res) => {
        if (res.code == 0) {
          console.log(res);
          if (res.code == 0) {
            let info = res.result.info;
            this.sonArea(0, info.province_id);
            this.sonArea(1, info.city_id);
            this.teacher_income_time = new Date(info.teacher_income_time * 1000);
            this.addForm = info;
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取省市区信息
    getArea() {
      return new Promise((resolve) => {
        let that = this;
        this.$axios
          .get(this.$api.area, {
            tree: 1,
            level: 2,
          })
          .then((res) => {
            if (res.code == 0) {
              that.area = res.result.list;
              resolve();
            }
          });
      });
    },
    //添加隔离人员
    saveForm() {
      let url = this.addForm.id
        ? this.$api.teacher.edit
        : this.$api.teacher.add;
      this.$axios.post(url, this.addForm).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.addForm.id ? "编辑成功" : "添加成功");
          this.$router.push("/home/LsolationPointMedicalStaff");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getTime(val) {
      if (val) this.addForm.teacher_income_time = val.getTime() / 1000;
    },
    // 三级联动选择地区
    sonArea(status, val) {
      if (status == 0) {
        let index = this.area.find((item) => item.id == val);
        this.cityArr = index._child;
        this.addForm.city_id = "";
      } else if (status == 1) {
        let index = this.cityArr.find((item) => item.id == val);
        this.areaArr = index._child;
        this.addForm.area_id = "";
      } else if (status == 2) {
        let index = this.areaArr.find((item) => item.id == val);
      }
    },
    //获取设备
    getShipperList() {
      this.$axios.post(this.$api.shipper.list, { rows: 10000 }).then((res) => {
        if (res.code == 0) {
          this.shipperList = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-form-item {
    width: 750px;
    text-align: left;
  }
  .city .box {
    display: flex;
    justify-content: space-between;
    .el-select {
      flex: 1;
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
